import { useState, useEffect } from "react";
import Offer from "./Offer";
import { Navigate, useNavigate } from "react-router-dom";
import { STEPS, useActiveStep } from "./Steps";
import { Auth } from "@cleeng/mediastore-sdk";
import { useTranslation } from "react-i18next";
import i18n from './translations';

const offers = [
    {
        id: 'S342460575',
        title: 'Monthly subscription',
        currency: '$',
        price: 25,
        period: 'month',
        subtext: `<p>
        クーポンコードを利用の場合は月額プランを選択ください。<br/>
        To redeem CouponCode, select Monthly Plan.</p>`
    },
    {
        id: 'S953087857',
        title: 'Annual subscription',
        currency: '$',
        price: 250,
        period: 'year'
    }
];

/* Poor mans enum :( */
const LoadingStates = Object.freeze({
    LOADING: 1,
    READY: 2,
    ERROR: 3,
});

const OfferList = () => {

    useActiveStep(STEPS.PLAN);
    const { t } = useTranslation('jme', { i18n });

    const [selectedOffer, setSelectedOffer] = useState(null);
    const [loadingState, setLoadingState] = useState(LoadingStates.LOADING);
    const [userCountry, setUserCountry] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const getLocation = async () => {
            try {
                const response = await fetch('https://jme-geoloc.bas-tuijnman.workers.dev/');
                const data = await response.json();
                setUserCountry(data.country);
            } catch (error) {
                console.error('Error fetching location:', error);
            }
        };
 
        getLocation();
    }, []);

    useEffect(() => {
        const fetchOffers = async () => {

            const user = localStorage.getItem('CLEENG_CUSTOMER_EMAIL');
            const requests = offers.map(offer => fetch(`https://mediastoreapi.cleeng.com/offers/${offer.id}/customers/${user}`));

            try {
                const responses = await Promise.all(requests);
                const data = await Promise.all(responses.map(response => response.json()));

                for (let offer of offers) {
                    let remoteOffer = data.find(o => o.responseData.offerId.includes(offer.id));
                    if (remoteOffer) {
                        offer.title = remoteOffer.responseData.offerTitle;
                        offer.price = remoteOffer.responseData.customerPriceInclTax;
                        offer.period = remoteOffer.responseData.period;
                        offer.currency = remoteOffer.responseData.customerCurrencySymbol;
                    }
                }

                setLoadingState(LoadingStates.READY);
            } catch {
                setLoadingState(LoadingStates.ERROR);
            }
        };

        fetchOffers().catch(() => setLoadingState(LoadingStates.ERROR));
    }, []);

    const onContinue = () => {

        // Don't continue without offer
        if (selectedOffer === null) {
            return;
        }

        navigate(`/purchase/${selectedOffer}`);
    }

    if (!Auth.isLogged()) {
        return <Navigate to="/" />;
    }

    if (loadingState === LoadingStates.LOADING) {
        return;
    }

    if (loadingState === LoadingStates.ERROR) {
        return (
            <div className="px-16">
                <h2 className="text-4xl text-white text-center py-10">Error, please refresh</h2>
            </div>
        );
    }

    return (
        <div className="px-16">
            <div><h2 className="text-4xl text-white text-center py-10">{t('select-your-plan')}</h2></div>

            {offers.map(({ id, title, currency, price, period, subtext }) => (
                <Offer
                    key={title}
                    title={t(title)}
                    currency={currency}
                    price={price}
                    period={t(period)}
                    subtext={subtext}
                    selected={selectedOffer === id}
                    onClick={() => setSelectedOffer(id)}
                />
            ))}

           {userCountry === 'CA' && (
               <div className="text-xs text-white text-opacity-75 text-center py-2">
                   <p>{t('USドルが課金日当日の為替レートでカナダドルに換算・請求されます。')}</p>
                   <p>{t('The amount in USD will be converted to CAD and charged based on the exchange rate on the billing date..')}</p>
               </div>
           )}

            <button onClick={onContinue} className="w-full p-2 bg-[#d61619] text-white text-center rounded-md mt-4">{t('continue')}</button>
        </div>
    );
}

export default OfferList;
